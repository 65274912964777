export enum AppUrls {
  HOME = '',
  TRAVEL_PROTECTION = 'travel-protection',
  PLAN_SELECTION = 'plan-selection',
  TRAVELER_INFO = 'traveler-info',
  ADDITIONAL_COVERAGE = 'additional-coverage',
  PAYMENT_INFO = 'payment-info',
  PROTECTED_SUCCESS = 'protected-success',
  LINK = 'link',
  FAQ = 'faq',
  WIDGET = 'widget',
}
