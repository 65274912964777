import {
  Injectable, computed, signal,
} from '@angular/core';
import { Params } from '@angular/router';
import { Traveler } from '@pages/home/interfaces/traveler.interface';
import { DateTime } from 'luxon';
import { FormTrigger } from '@pages/home/interfaces/input-trigger.interface';
import { formTriggers } from '@pages/home/data/input-triggers.const';
import { FormStep } from '@pages/home/enums/form-step.enum';
import { PaymentLeft } from '@pages/home/enums/payments-left.enum';
import { USState } from '../interfaces/us-state.interface';
import { US_STATES } from '../const/us-states.const';
import { HomepageForm } from '../interfaces/homepage-form.interface';
import { ShouldResetHomeForm } from '../enums/form-reset.enum';

@Injectable({
  providedIn: 'root',
})
export class FormDataService {
  public formData: Record<FormStep, { [prop: string]: any }> = {} as Record<
  FormStep,
  { [prop: string]: any }
  >;

  public formDataSignal = signal(this.formData);

  public summaryData = signal<FormTrigger[]>([...formTriggers]);

  public currentStep = signal<FormStep | null>(null);

  private dropdownValue = signal<USState | undefined>(undefined);

  private dropdownFilter = signal<string>('');

  public filteredList = computed(() =>
    US_STATES.filter((state) =>
      state.name.toLowerCase().includes(this.dropdownFilter().toLowerCase())));

  private trackValidity = signal({
    [FormStep.One]: false,
    [FormStep.Two]: false,
    [FormStep.Three]: false,
    [FormStep.Four]: false,
  });

  public isFormValid = computed(() => Object.values(this.trackValidity()).every((v) => v));

  public depositSum: number = 0;

  public formDataFormSubmit: HomepageForm = {
    countryIsoCode: 'US',
    destinations: [],
  } as unknown as HomepageForm;

  public paymentCompleteVal = { name: PaymentLeft.YES };

  public resetHomePageFormSignal = signal(ShouldResetHomeForm.NO);

  get dropdownValueSignal() {
    return this.dropdownValue;
  }

  get dropdownFilterSignal() {
    return this.dropdownFilter;
  }

  updateDropdownSelectedValue(value: USState) {
    this.dropdownValue.set(value);
  }

  updateDropdownFilter(value: string) {
    this.dropdownFilter.set(value);
  }

  public updateCurrentStep(step: FormStep | null) {
    this.currentStep.set(step);
  }

  updateResetState() {
    if (this.resetHomePageFormSignal() === ShouldResetHomeForm.YES) {
      this.resetHomePageFormSignal.set(ShouldResetHomeForm.NO);
    }
  }

  updateFormData(data: { [prop: string]: any }) {
    if (!this.currentStep()) {
      return;
    }

    this.formData[this.currentStep()!] = data;

    this.formDataSignal.update((d) => ({ ...d, [this.currentStep()!]: data }));

    this.updateValidity(true);
  }

  updateFormInvalid() {
    this.updateValidity(false);
  }

  updatePaymentComplete(val:{ name:PaymentLeft }) {
    this.paymentCompleteVal = val;
  }

  updateSummaryData(data: { [prop: string]: any }, options?:any) {
    const formData = Object.values(data);

    const trigger = this.findTriggerByStep(this.currentStep());
    switch (this.currentStep()) {
      case null:
        return;
      case FormStep.One: {
        const travelers = formData[0] as Traveler[];
        const travelerCost = travelers.reduce(
          (acc, next) => acc + parseInt(next.cost, 10),
          0,
        );

        const subtitle = `${travelers.length} Traveler${travelers.length > 1 ? 's' : ''}, $${this.thousandSeparator(travelerCost || 0)}`;
        const updatedSummaryArray = this.summaryData().map((t) =>
          (t.step === this.currentStep() ? { ...t, subtitle } : t));
        this.summaryData.set(updatedSummaryArray);

        const travelersForSubmit = travelers.map((t) => ({
          dateOfBirth: DateTime.fromFormat(t.birthdate, 'MM/dd/y').toFormat(
            'MM/dd/y',
          ),
          tripCost: parseInt(t.cost, 10),
        }));

        this.formDataFormSubmit.primaryTraveler = travelersForSubmit[0];

        this.formDataFormSubmit.additionalTravelers = travelersForSubmit.slice(1);
        break;
      }
      case FormStep.Two: {
        let subtitle;

        if ((formData[0] as Date[]).every((d) => !!d)) {
          const transformedRange = (formData[0] as Date[]).map((d) =>
            this.transformDate(d));

          subtitle = `${transformedRange[0]} - ${transformedRange[1]}`;
        } else {
          subtitle = trigger!.subtitle;
        }
        const updatedSummaryArray = this.summaryData().map((t) =>
          (t.step === this.currentStep() ? { ...t, subtitle } : t));

        this.formDataFormSubmit.departureDate = this.prepareDateForSubmit(
          formData[0][0],
        );

        this.formDataFormSubmit.returnDate = this.prepareDateForSubmit(
          formData[0][1],
        );

        this.summaryData.set(updatedSummaryArray);
        break;
      }
      case FormStep.Three: {
        const filter = (formData[0] as USState)?.name || '';
        const updatedSummaryArray = this.summaryData().map((t) =>
          (t.step === this.currentStep() ? { ...t, filter, subtitle: filter || t['initSubtitle'] } : t));
        this.summaryData.set(updatedSummaryArray);

        if (filter) {
          this.formDataFormSubmit.stateIsoCode = (
            formData[0] as USState
          ).abbreviation;
        }

        break;
      }
      case FormStep.Four: {
        const updatedSummaryArray = this.summaryData().map((t) =>
          (t.step === this.currentStep()
            ? {
              ...t,
              subtitle: options.paymentLeft ? `${this.transformPaymentSubtitle(formData[0], formData[1])}` : `$0, ${this.transformDate(formData[0])}`,
            }
            : t));
        this.summaryData.set(updatedSummaryArray);

        this.formDataFormSubmit.depositDate = this.prepareDateForSubmit(
          typeof formData[0] === 'number' ? formData[1] : formData[0],
        );
        this.depositSum = typeof formData[0] === 'number' ? formData[0] : formData[1] || 0;
        break;
      }

      default:
        break;
    }
  }

  transformPaymentSubtitle(
    payment: number | undefined,
    date: Date | undefined,
  ) {
    if (!payment && !date) {
      return 'Add date';
    }

    if (!payment && date) {
      return this.transformDate(date);
    }

    if (payment && !date) {
      return `$${this.thousandSeparator(payment)}`;
    }

    return `$${this.thousandSeparator(payment || 0)}, ${this.transformDate(date)}`;
  }

  transformDate(date: Date | undefined) {
    return date ? DateTime.fromJSDate(date!).toFormat('MMM dd yy') : '';
  }

  prepareDateForSubmit(date: Date | null) {
    return date ? DateTime.fromJSDate(date).toFormat('MM/dd/y') : DateTime.now().toFormat('MM/dd/y');
  }

  findTriggerByStep(step: FormStep | null) {
    return this.summaryData().find((s) => s.step === step);
  }

  thousandSeparator(data: number) {
    return data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  updateSummaryFromTravelProtection(data:HomepageForm) {
    const travelersNum = (data.additionalTravelers?.length || 0) + 1;
    const tripCost = (data?.additionalTravelers?.reduce((acc, t) => acc + t.tripCost, 0) || 0)
      + (data.primaryTraveler?.tripCost || 0);
    const travelDateFrom = this.format(data.departureDate);
    const travelDateTo = this.format(data.returnDate);
    const initDepositDate = this.format(data.depositDate);
    const state = US_STATES.find((s) => s.abbreviation === data.stateIsoCode)?.name;

    this.formDataFormSubmit = data;

    const travelers = [data.primaryTraveler, ...data.additionalTravelers].map((t) => ({ birthdate: t.dateOfBirth, cost: t.tripCost }));
    this.formData = {
      ...this.formData,
      [FormStep.One]: {
        travelers,
      },
      [FormStep.Three]: {
        state: US_STATES.find((s) => s.abbreviation === data.stateIsoCode),
      },
      [FormStep.Four]: { ...this.formData[FormStep.Four], date: data.depositDate },
    };

    this.summaryData.update((summary) => summary.map((s) => {
      switch (s.step) {
        case FormStep.One:
          return {
            ...s,
            subtitle: `${travelersNum} traveler${travelersNum > 1 ? 's' : ''}, $${this.thousandSeparator(tripCost)}`,
          } as FormTrigger;
        case FormStep.Two:
          return {
            ...s,
            subtitle: `${travelDateFrom} - ${travelDateTo}`,
          } as FormTrigger;
        case FormStep.Three:
          this.formDataFormSubmit.stateIsoCode = data.stateIsoCode;
          return {
            ...s,
            subtitle: state,
            filter: '',
          } as FormTrigger;
        case FormStep.Four:
          return {
            ...s,
            subtitle: `$${this.depositSum}, ${initDepositDate}`,
          } as FormTrigger;
        default: return s as FormTrigger;
      }
    }));
  }

  format(date:string) {
    return DateTime.fromFormat(date, 'MM/dd/yyyy').toFormat('MMM dd yy');
  }


  public getWidgetQueryParams(): string {
    const { returnDate, departureDate, countryIsoCode, stateIsoCode } = this.formDataFormSubmit;

    return new URLSearchParams({ returnDate, departureDate, countryIsoCode, stateIsoCode }).toString()
  }

  public updateFormDataFromQueryParams(params: Params): void {
    const { returnDate, departureDate, countryIsoCode, stateIsoCode } = params;

    if (returnDate && departureDate && countryIsoCode && stateIsoCode) {
      this.formDataFormSubmit = {
        countryIsoCode,
        stateIsoCode,
        returnDate,
        departureDate,
        destinations: [],
      } as unknown as HomepageForm;

      const dateRange = [new Date(departureDate), new Date(returnDate)];
      const dateRangeTitle = dateRange.map((date) => this.transformDate(date)).join(' - ');
      const state = US_STATES.find((state) => state.abbreviation === stateIsoCode);

      this.formData[FormStep.Two] = { dateRange };
      this.formData[FormStep.Three] = { state: state ?? {} };

      const updatedSummaryArray = this.summaryData().map((formTrigger) => {
        if (formTrigger.step === FormStep.Two) {
          return { ...formTrigger, subtitle: dateRangeTitle };
        }

        if (formTrigger.step === FormStep.Three && state) {
          return { ...formTrigger, subtitle: state.name };
        }

        return formTrigger;
      });

      this.trackValidity.set({
        [FormStep.One]: false,
        [FormStep.Two]: true,
        [FormStep.Three]: true,
        [FormStep.Four]: false,
      });

      this.summaryData.set(updatedSummaryArray);
    }
  }

  public resetForm() {
    this.formData = {} as Record<
    FormStep,
    { [prop: string]: any }
    >;
    this.resetHomePageFormSignal.set(ShouldResetHomeForm.YES);

    this.formDataFormSubmit = {
      countryIsoCode: 'US',
      destinations: [],
    } as unknown as HomepageForm;

    this.dropdownFilter.set('');

    const triggersReset = formTriggers.map((t) => (t.filter ? { ...t, filter: '' } : t));
    this.summaryData.set(triggersReset);

    this.currentStep.set(null);

    this.trackValidity.set({
      [FormStep.One]: false,
      [FormStep.Two]: false,
      [FormStep.Three]: false,
      [FormStep.Four]: false,
    });
  }

  private updateValidity(isValid:boolean) {
    if (!this.currentStep()) {
      return;
    }
    this.trackValidity.update((v) => ({
      ...v,
      [this.currentStep()!]: isValid,
    }));
  }

  updateFormValidityOnNavigationToShared() {
    this.trackValidity.set({
      [FormStep.One]: true,
      [FormStep.Two]: true,
      [FormStep.Three]: true,
      [FormStep.Four]: true,
    });
  }
}
